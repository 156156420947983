import Text from "../_widgets/Text/Text";
import Input from "../_widgets/Input/Input";
import noData from "../../Assets/img/noData.png";
import Button from "../_widgets/Button/Button";
import NavBar from "../_widgets/NavBar/NavBar";
import { Row, Col, Table } from "react-bootstrap";
import CustomPagination from "../_widgets/Pagination/Pagination";
import DateRangePicker from "../_widgets/DateRangePicker/DateRangePicker";
import AnimatedPage from "../AnimatedPage/AnimatedPage";
import CustomTooltip from '../_widgets/Tooltip/CustomTooltip'
import IconResource from '../../Wrapper/IconResource/IconResource';
import MainLoader from "../_widgets/Loader/MainLoader";
import AddEditModal from "./AddEditModal";
import { useNavigate } from "react-router";
import PaymentModal from "./PaymentModal";
import { getColorFromStatus, getColorOfStatus } from "../../Utils/appUtils";
import { capitalizeFirstLetter } from "../../Utils/stringUtils";

const DispatcherUI = ({ modalShow, fetchDispatcherList, setModalShow, handleClose, dispatcherList, onPageChange, activePage, buttonLoading, loading, dates, setDates, datePickerStartRef, datePickerEndRef, searchQuery, setSearchQuery, dispatcherData, setDispatcherData, upsertBooking, setCustomerSearchQuery, setVehicleSearchQuery, paymentList, paymentData, getPaymentList, setPayentData, upsertPayment, paymentModal, handlePaymentClose, handlePayment, deleteAmount, dispatcherDetail, onPageChangePayment, activePaymentPage, paymentLoading, handleAddRow, clearRow, paymentSearchQuery, setPaymentSearchQuery, paymentDates, setPaymentDates, paymentdatePickerEndRef, paymentdatePickerStartRef, setDispatcherDetails, setPaymentList, validatePayment, deletePayment, setAddedPayment, paymentButtonLoading }) => {
    const navigate = useNavigate()


    return (
        <AnimatedPage>
            <NavBar
                button="Add Dispatcher"
                buttonIcon={"users"}
                heading={`All Dispatchers`}
                onClick={() => document.body.classList.toggle("side_active")}
                setModalShow={setModalShow}
                Icon="menu"
            />

            <PaymentModal
                paymentLoading={paymentLoading}
                modalShow={paymentModal}
                handleClose={handlePaymentClose}
                heading='Payment'
                buttonLoading={paymentButtonLoading}
                button1Click={upsertPayment}
                button1Text={'Make Payment'}
                paymentList={paymentList}
                paymentData={paymentData}
                setPayentData={setPayentData}
                dispatcherDetail={dispatcherDetail}
                deleteAmount={deleteAmount}
                onPageChangePayment={onPageChangePayment}
                activePage={activePaymentPage}
                handleAddRow={handleAddRow}
                clearRow={clearRow}
                paymentSearchQuery={paymentSearchQuery}
                setPaymentSearchQuery={setPaymentSearchQuery}
                getPaymentList={getPaymentList}
                paymentDates={paymentDates}
                setPaymentDates={setPaymentDates}
                paymentdatePickerStartRef={paymentdatePickerStartRef}
                paymentdatePickerEndRef={paymentdatePickerEndRef}
                setPaymentList={setPaymentList}
                validatePayment={validatePayment}
                deletePayment={deletePayment}
                setAddedPayment={setAddedPayment}
                remarks

                size="md"
            />

            <AddEditModal
                modalShow={modalShow}
                handleClose={handleClose}
                dispatcherData={dispatcherData}
                upsertBookings={upsertBooking}
                buttonLoading={buttonLoading}
                setDispatcherData={setDispatcherData}

                setCustomerSearchQuery={setCustomerSearchQuery}
                setVehicleSearchQuery={setVehicleSearchQuery}
            />

            <div className="patient_list white_card">
                <div className="top_bar">
                    <Text type="h2" text="" />

                    <Row className="search_filter">
                        <Col lg={4} xs={12} sm={4}>
                            <div>
                                <Input
                                    placeholder="Search"
                                    id="searchInput"
                                    suffixIcon={"search"}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    onClickIconSuff={() => fetchDispatcherList(true)}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            fetchDispatcherList(true)
                                        }
                                    }}
                                />
                            </div>
                        </Col>

                        <Col lg={8} sm={8}>
                            <div className="date_range_picker">
                                <DateRangePicker
                                    startDate={dates?.startDate}
                                    setStartDate={(startDate) => setDates({ ...dates, startDate })}
                                    endDate={dates?.endDate}
                                    setEndDate={(endDate) => setDates({ ...dates, endDate })}
                                    onChange={(startDate, endDate) => setDates({ startDate, endDate })}
                                    datePickerStartRef={datePickerStartRef}
                                    datePickerEndRef={datePickerEndRef}
                                    showDateShortcuts={true}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="custom_table position-relative">
                    {loading
                        ? <MainLoader />
                        : dispatcherList?.data?.length > 0 ? (
                            <>
                                <div>
                                    <Table responsive hover>
                                        <thead>
                                            <tr>
                                                <th className="text-start">Name</th>
                                                <th className="text-start">Business Name</th>
                                                <th>Email</th>
                                                <th>Mobile Number</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dispatcherList?.data.map(i => <tr onClick={() => navigate(`/dispatcher-detail/${i?.id}`)}>
                                                    <td className="text-start">{capitalizeFirstLetter(i?.name)}</td>
                                                    <td className="text-start">{capitalizeFirstLetter(i?.business_name)}</td>
                                                    <td>{i?.email}</td>
                                                    <td>{i?.mno}</td>
                                                    <td>
                                                        <div className={`status status__${getColorFromStatus(i.status === 0 ? "ACTIVE" : "INACTIVE")}`}>
                                                            <Text text={capitalizeFirstLetter(i.status === 0 ? "ACTIVE" : "INACTIVE")} />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center gap-2 justify-content-center">
                                                            <CustomTooltip text="Edit" placement={"top"}>
                                                                <Button
                                                                    className="p-1 bg-transparent border-0"
                                                                    icon={
                                                                        <IconResource type="edit" color="var(--light-text)" />
                                                                    }
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setDispatcherData(i)
                                                                        setDispatcherDetails(i)
                                                                        setModalShow(!modalShow)
                                                                    }}
                                                                />
                                                            </CustomTooltip>
                                                            <CustomTooltip text={"Make Payment"} placement={"top"}>
                                                                <Button
                                                                    className="p-1 bg-transparent border-0"
                                                                    icon={<IconResource type="rupee" />}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setDispatcherDetails(i)
                                                                        getPaymentList(i)
                                                                        handlePayment()
                                                                    }}
                                                                />
                                                            </CustomTooltip>
                                                        </div>
                                                    </td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                <CustomPagination
                                    activePage={activePage}
                                    onPageChange={onPageChange}
                                    pageLength={dispatcherList?.per_page}
                                    totalData={dispatcherList?.total}
                                    lastPage={dispatcherList?.last_page}
                                />
                            </>
                        ) : (
                            <>
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th className="text-start">Name</th>
                                            <th className="text-start">Business Name</th>
                                            <th>Email</th>
                                            <th>Mobile Number</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </Table>
                                <div className="no_data position-relative">
                                    <img
                                        src={noData}
                                        className="img-fluid"
                                        style={{ width: "300px" }}
                                    />
                                    <p className="text-center">There is no data to display.</p>
                                </div>
                            </>
                        )}
                </div>
            </div>
        </AnimatedPage>
    );
}

export default DispatcherUI;
