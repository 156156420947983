import { useRef, useState, useEffect } from 'react'
import * as apiHelper from "../../Services/ApiHelper";
import * as toast from "../../Wrapper/toast/toast";
import { changeDateFormatForServer, nowTime, timeFormatForServer } from "../../Utils/dateUtils";
import { isNullOrEmpty } from '../../Utils/stringUtils';
import * as stringConstants from "../../Constants/stringConstants";
import { isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getLocalData, isClientLogin, isEmailValid, removeCommaFromAmount, setUsersListInLocal, setVehicleListInLocal } from '../../Services/common';
import { CLIENT_ID, USERS, VEHICLES } from '../../Constants/storageConstant';
import { debounceFunction } from '../../Utils/appUtils';
import DispatcherDetailUI from "./DispatcherDetailUI";
import { useParams } from 'react-router-dom';

const DispatcherDetail = () => {
  const navigate = useNavigate();
  let { dispatcherID } = useParams()

  const [modalShow, setModalShow] = useState(false);
  const [dispatcherBookingList, setDispatcherBookingList] = useState([])
  const [bookingList, setBookingList] = useState([]);
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [searchQuery, setSearchQuery] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [cancelId, setDeleteId] = useState('');
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [customerSearchQuery, setCustomerSearchQuery] = useState('');
  const [vehicleSearchQuery, setVehicleSearchQuery] = useState('');

  const datePickerStartRef = useRef();
  const datePickerEndRef = useRef();
  const handleBack = () => {
    navigate(-1);
  }

  const initialBookingData = {
    id: null,
    package_id: null,
    start_city: "",
    start_city_name: "",
    end_city: "",
    end_city_name: "",
    start_lat_lng: "",
    end_lat_lng: "",
    driver_id: null,
    vehicle_id: null,
    notes: "",
    status: null,
    travel_date: new Date(),
    travel_time: nowTime("HH:mm"),
    cust_id: null,
    cust_name: "",
    client_id: isClientLogin() ? getLocalData(CLIENT_ID) : null,
    cust_number: "",
    final_amt: null,
    fixed_amt: null,
    pending_amt: null,
    gst: 0,
    action: "Add",
    cancelRemarks: "",
    startPointOptions: [],
    endPointOptions: [],
    userOptions: [],
    vehicleOptions: [],
  }

  const initialPaymentData = {
    amount: "",
    remark: ""
  }
  const initialDispatcherData = {
    id: null,
    owner: null,
    name: "",
    business_name: "",
    email: "",
    mno: "",
    alt_mno: null,
    image: "",
    status: 0,
    address: "",
    remark: ""
  }
  const [bookingData, setBookingData] = useState(initialBookingData);
  const [paymentModal, setPaymentModal] = useState(false)
  const [paymentData, setPayentData] = useState(initialPaymentData)
  const [paymentList, setPaymentList] = useState([]);
  const [activePaymentPage, setActivePaymentPage] = useState(1);
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [paymentRows, setPaymentRows] = useState([])
  const paymentdatePickerStartRef = useRef();
  const paymentdatePickerEndRef = useRef();
  const [paymentSearchQuery, setPaymentSearchQuery] = useState("")
  const [paymentDates, setPaymentDates] = useState({ startDate: null, endDate: null })
  const [addedPayment, setAddedPayment] = useState([])
  const [editModal, setEditModal] = useState(false)
  const [dispatcherData, setDispatcherData] = useState(initialDispatcherData)

  const getDispatcherBooking = async () => {
    setLoading(true);
    const query = `?page=${searchQuery ? 1 : activePaymentPage}&limit=10&startDate=${changeDateFormatForServer(dates.startDate)}&endDate=${changeDateFormatForServer(dates.endDate)}&query=${searchQuery}`;

    searchQuery && setActivePaymentPage(1)

    const response = await apiHelper.getDispatcherBooking(dispatcherID, query);
    if (response?.isSuccess) {
      setLoading(false);
      setDispatcherBookingList(response?.data)
    }
    else {
      setLoading(false);
      toast.warn(response?.message)
    }
  }
  useEffect(() => {
    if (((paymentDates.startDate !== null && paymentDates.endDate !== null) || (paymentDates.startDate === null && paymentDates.endDate === null))) {
      if (paymentModal === true) {
        getPaymentList(dispatcherID)
      }
    }
  }, [activePaymentPage, paymentDates]);
  useEffect(() => {
    if (((dates.startDate !== null && dates.endDate !== null) || (dates.startDate === null && dates.endDate === null))) {
      getDispatcherBooking();
    }
  }, [activePage, dates, selectedStatus, selectedDriver, selectedPackage]);
  useEffect(() => {
    fetchDispatcherDetails(dispatcherID)
  }, [editModal])
  const onPageChange = (page) => {
    if (page !== activePage) {
      setActivePage(page);
    }
  };

  const getBookingsList = (search) => {
    const query = `?page=${search ? 1 : activePage}&limit=10`;
    const data = {
      startDate: changeDateFormatForServer(dates.startDate),
      endDate: changeDateFormatForServer(dates.endDate),
      query: searchQuery,
      status: selectedStatus,
      drivers: selectedDriver,
      packages: selectedPackage,
    }
    search && setActivePage(1)
    setLoading(true);
    apiHelper.getDispatcherBooking(query, data).then((response) => {
      if (response?.isSuccess) {
        setBookingList(response.data);
      } else {
        toast.warn(response?.message);
      }
      setLoading(false);
    });
  }



  let initialPlacesOptions = [];





  const handleClose = () => {
    setModalShow(false);
    setBookingData({ ...initialBookingData, userOptions: bookingData.userOptions, vehicleOptions: bookingData.vehicleOptions, startPointOptions: initialPlacesOptions, endPointOptions: initialPlacesOptions });
  }


  const handleDeleteClick = (e, id) => {
    e.stopPropagation();
    setDeleteId(id);
    setShowConfirmModal(true);
  }



  const openBookingDetail = id => navigate(`/bookings/${id}`);

  const handleStatusChange = (options) => setSelectedStatus(options);
  const handleDriverChange = (options) => setSelectedDriver(options);
  const handlePackageChange = (options) => setSelectedPackage(options);


  const clearFilter = (e, state) => {
    const dropdownMenu = e.target.closest('#dropdown-basic');
    dropdownMenu.click();
    // e.stopPropagation();
    state([])
  }

  const upsertPayment = () => {


    if (addedPayment?.length > 0) {
      setButtonLoading(true)

      let data = {
        "payments": addedPayment
      }

      apiHelper.upsertPayment(dispatcherID, data).then(response => {

        if (response?.isSuccess) {
          toast.success(response?.message);
          setButtonLoading(false)
          setPayentData(initialPaymentData)
          getPaymentList(dispatcherID, stringConstants.NOLOADING)
          getDispatcherBooking()
          setPaymentModal(false)
          setAddedPayment([])
          handlePaymentClose()
        } else {
          setButtonLoading(false)
          setPayentData(initialPaymentData)
          getPaymentList(dispatcherID, stringConstants.NOLOADING)
          getDispatcherBooking()
          setPaymentModal(false)
          setAddedPayment([])
          toast.warn(response?.message);
          setAddedPayment([])
          handlePaymentClose()

        }

      }).finally(() => setButtonLoading(false))


    }
    else {
      setButtonLoading(false)
      setPayentData(initialPaymentData)
      getPaymentList(dispatcherID, stringConstants.NOLOADING)
      setPaymentModal(false)
      setAddedPayment([])
      handlePaymentClose()

    }

  };
  const validatePayment = () => {

    let validData = true;
    const {
      remark, amount } = paymentData;
    if (isNullOrEmpty(amount)) {
      toast.warn(stringConstants.AMOUNT_REQUIRED);
      validData = false;
    }

    return validData
  }
  const handlePayment = () => {
    getPaymentList(dispatcherID)
    setPaymentModal(true)
  }
  const handleEdit = () => {
    setEditModal(true)
  }
  const handlePaymentClose = () => {

    setPayentData(initialPaymentData);
    setPaymentList([]);
    setPaymentModal(false);

    setPaymentDates({ startDate: null, endDate: null })
    setPaymentSearchQuery("");
    setActivePaymentPage(1)
  }
  const deleteAmount = () => {
    toast.warn("Option will be available soon")
  }
  useEffect(() => {
    if (paymentModal === true) {
      getPaymentList(dispatcherID)
    }
  }, [activePaymentPage]);
  const getPaymentList = (id, loading) => {

    if (loading !== stringConstants.NOLOADING) {
      setPaymentLoading(true)
    }
    const query = `?page=${paymentSearchQuery ? 1 : activePaymentPage}&limit=10&startDate=${changeDateFormatForServer(paymentDates.startDate)}&endDate=${changeDateFormatForServer(paymentDates.endDate)}&query=${paymentSearchQuery}`;

    paymentSearchQuery && setActivePaymentPage(1)


    apiHelper.getPaymentList(query, id).then((response) => {
      if (response?.isSuccess) {
        if (loading !== stringConstants.NOLOADING) {
          setPaymentLoading(false)
        }
        setPaymentList(response.data);
      } else {
        if (loading !== stringConstants.NOLOADING) {
          setPaymentLoading(false)
        }
        toast.warn(response?.message);
      }
      setLoading(false);
    });

  }
  const onPageChangePayment = (page) => {

    if (page !== activePaymentPage) {
      setActivePaymentPage(page);
    }
  };
  const handleAddRow = (data) => {

    setPayentData(initialPaymentData)
    setPaymentRows((oldData) => {
      return [...oldData, data]
    })
  }
  const clearRow = () => {
    setPayentData(initialPaymentData)
  }
  const deletePayment = (id) => {
    apiHelper.deletePayment(id).then((res) => {
      if (res.isSuccess) {
        getDispatcherBooking()
        getPaymentList(dispatcherID, stringConstants.NOLOADING)
        toast.success(res.message)
      }
      else {
        toast.warn(res.message)
      }
    })
  }
  const handleDispatcherClose = () => {

    setEditModal(false);
    setDispatcherData(initialDispatcherData);


    setDispatcherData({
      ...initialDispatcherData,
      userOptions: dispatcherData.userOptions,
      vehicleOptions: dispatcherData.vehicleOptions,
      startPointOptions: initialPlacesOptions,
      endPointOptions: initialPlacesOptions
    });
  };

  const upsertBooking = async () => {
    if (validateData()) {
      setButtonLoading(true);
      const { address, alt_mno, business_name, email, image, mno, name, owner, remark, id, password } = dispatcherData;

      const data = new FormData();
      data.append('address', address || '');
      data.append('alt_mno', alt_mno || '');
      data.append('business_name', business_name);
      data.append('email', email);
      data.append('mno', mno);
      data.append('name', name);
      data.append('owner', owner);
      data.append('remark', remark);
      data.append('password', password);

      if (id !== null) {
        data.append('id', id);  // Only append the ID if it is not null
      }

      // Handle the image upload
      if (image) {
        if (typeof image === 'string') {  // If the image is a URL (string)
          try {
            const imageFile = await convertImageUrlToFile(image);

            // Append the image as a file
            data.append('image', imageFile);
          } catch (error) {
            console.error("Failed to convert image URL to File", error);
          }
        } else if (image instanceof File) {  // If the image is a file object
          data.append('image', image);  // Append the file directly
        }
      }

      // Make the API request with FormData
      try {
        const response = await apiHelper.upsertDispatcher(data);
        setButtonLoading(false);
        if (response?.isSuccess) {
          toast.success(response?.message);
          handleDispatcherClose();
          fetchDispatcherDetails(dispatcherID)
          // fetchDispatcherList();
        } else {
          toast.warn(response?.message);
        }
      } catch (error) {
        console.error("Error during API request", error);
        setButtonLoading(false);
      }
    }
  };
  const validateData = () => {

    const {
      address, business_name, email, mno, name, owner, alt_mno
    } = dispatcherData;
    let validData = true;

    if (isNullOrEmpty(business_name)) {
      toast.warn(stringConstants.BUSINESS_NAME_REQUIRED);
      validData = false;
    }
    else if (isNullOrEmpty(name)) {
      toast.warn(stringConstants.DISPATCHER_NAME_REQUIRED);
      validData = false;
    }
    else if (isNullOrEmpty(email)) {
      toast.warn(stringConstants.EMAIL_REQUIRED);
      validData = false;
    }
    else if (!isEmailValid(email)) {
      toast.warn(stringConstants.INCORRECT_EMAIL);
      validData = false;
    }
    else if (isNullOrEmpty(mno)) {
      toast.warn(stringConstants.INCORRECT_CUST_NUMBER);
      validData = false;
    }
    else if (!isValidPhoneNumber(mno)) {
      toast.warn(stringConstants.INCORRECT_PHONE);
      validData = false;
    }
    // else if (alt_mno === stringConstants.UNDEFINED && !isValidPhoneNumber(alt_mno)) {
    //     toast.warn(stringConstants.INVALID_MOBILE_NUMBER);
    //     validData = false
    // }
    else if (isNullOrEmpty(address)) {
      toast.warn(stringConstants.DISPATCHER_ADDRESS_REQUIRED);
      validData = false;
    }



    return validData;
  };
  const getFilenameFromUrl = (url) => {
    return url.split('/').pop();  // Extracts the filename from the URL
  };
  const convertImageUrlToFile = async (imageUrl) => {
    try {
      // Fetch the image from the URL
      const response = await fetch(imageUrl);

      // Check if the response is OK (status 200)
      if (!response.ok) {
        throw new Error('Failed to fetch image. Status: ' + response.status);
      }

      // Check if the response is of type image/jpeg, image/png, or other image type
      const contentType = response.headers.get('Content-Type');
      if (!contentType || !contentType.startsWith('image/')) {
        throw new Error('The URL does not point to an image. Content-Type: ' + contentType);
      }

      // Convert the response to a Blob (binary large object)
      const blob = await response.blob();

      // Extract the filename from the URL
      const fileName = getFilenameFromUrl(imageUrl);

      // Create a new File object with the blob, filename, and MIME type
      const file = new File([blob], fileName, {
        type: contentType,  // Ensure the MIME type is correctly passed
        lastModified: Date.now(),
      });


      return file;
    } catch (error) {
      console.error("Error converting image URL to file:", error);
      throw error;
    }
  };
  const fetchDispatcherDetails = (id) => {



    apiHelper.getDispatcherDetails(id).then((response) => {
      if (response?.isSuccess) {

        setDispatcherData(response.data);
      } else {

        toast.warn(response?.message);
      }

    });

  }
  return (
    <DispatcherDetailUI
      bookingList={bookingList}
      loading={loading}
      buttonLoading={buttonLoading}
      modalShow={modalShow}
      setModalShow={setModalShow}
      handleClose={handleClose}
      activePage={activePage}
      onPageChange={onPageChange}
      showConfirmModal={showConfirmModal}
      setShowConfirmModal={setShowConfirmModal}

      dates={dates}
      setDates={setDates}
      datePickerStartRef={datePickerStartRef}
      datePickerEndRef={datePickerEndRef}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}

      handleDeleteClick={handleDeleteClick}
      bookingData={bookingData}
      setBookingData={setBookingData}

      getBookingsList={getBookingsList}
      openBookingDetail={openBookingDetail}
      selectedStatus={selectedStatus}
      selectedDriver={selectedDriver}
      selectedPackage={selectedPackage}
      handleStatusChange={handleStatusChange}
      handleDriverChange={handleDriverChange}
      handlePackageChange={handlePackageChange}
      setCustomerSearchQuery={setCustomerSearchQuery}
      setVehicleSearchQuery={setVehicleSearchQuery}
      clearFilter={clearFilter}
      setSelectedStatus={setSelectedStatus}
      setSelectedDriver={setSelectedDriver}
      setSelectedPackage={setSelectedPackage}
      handleBack={handleBack}
      dispatcherBookingList={dispatcherBookingList}
      paymentModal={paymentModal}
      setPaymentModal={setPaymentModal}
      paymentData={paymentData}
      handlePayment={handlePayment}
      handlePaymentClose={handlePaymentClose}
      deleteAmount={deleteAmount}
      upsertPayment={upsertPayment}
      setPayentData={setPayentData}
      paymentList={paymentList}
      setPaymentList={setPaymentList}
      onPageChangePayment={onPageChangePayment}
      activePaymentPage={activePaymentPage}
      paymentLoading={paymentLoading}
      getDispatcherBooking={getDispatcherBooking}
      handleAddRow={handleAddRow}
      paymentRows={paymentRows}
      clearRow={clearRow}
      paymentSearchQuery={paymentSearchQuery}
      setPaymentSearchQuery={setPaymentSearchQuery}
      paymentDates={paymentDates}
      setPaymentDates={setPaymentDates}
      paymentdatePickerStartRef={paymentdatePickerStartRef}
      paymentdatePickerEndRef={paymentdatePickerEndRef}
      getPaymentList={getPaymentList}
      type={"details"}
      validatePayment={validatePayment}
      deletePayment={deletePayment}
      setAddedPayment={setAddedPayment}
      handleEdit={handleEdit}
      editModal={editModal}
      handleDispatcherClose={handleDispatcherClose}
      dispatcherData={dispatcherData}
      upsertBooking={upsertBooking}
      setDispatcherData={setDispatcherData}
    />
  );
};

export default DispatcherDetail;
