import { MAX_AMT_LIMIT_REACHED } from '../../Constants/stringConstants';
import { formattedValue } from '../../Services/common';
import { showDate } from '../../Utils/dateUtils';
import { isNullOrEmpty } from '../../Utils/stringUtils';
import IconResource from '../../Wrapper/IconResource/IconResource';
import { warn } from '../../Wrapper/toast/toast';
import Input from '../_widgets/Input/Input';
import BasicModal from '../_widgets/Modal/BasicModal';
import { Col, Row, Table } from 'react-bootstrap';
import CustomPagination from '../_widgets/Pagination/Pagination';
import MainLoader from '../_widgets/Loader/MainLoader';
import noData from "../../Assets/img/noData.png";
import CustomTooltip from '../_widgets/Tooltip/CustomTooltip';
import Text from '../_widgets/Text/Text';
import DateRangePicker from '../_widgets/DateRangePicker/DateRangePicker';
const PaymentModal = ({ modalShow, handleClose, paymentList, setPayentData, paymentData, button1Click, buttonLoading, dispatcherDetail, onPageChangePayment, activePage, paymentLoading, clearRow, paymentSearchQuery, setPaymentSearchQuery, getPaymentList, paymentDates, setPaymentDates, paymentdatePickerEndRef, paymentdatePickerStartRef, type, setPaymentList, validatePayment, deletePayment, setAddedPayment }) => {

    function getFormattedDate() {
        // Get current date and time
        const now = new Date();

        // Get date components
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-based
        const day = String(now.getUTCDate()).padStart(2, '0');
        const hours = String(now.getUTCHours()).padStart(2, '0');
        const minutes = String(now.getUTCMinutes()).padStart(2, '0');
        const seconds = String(now.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0'); // Milliseconds are available but not microseconds

        // Generate the date string in the required format
        // Append '000000' to represent microseconds since JavaScript doesn't support microseconds directly
        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}000000Z`;

        return formattedDate;
    }

    const dateStr = getFormattedDate();


    return (
        <BasicModal
            show={modalShow}
            size="lg"
            handleClose={handleClose}
            heading={`Payment - ${dispatcherDetail?.name} (${dispatcherDetail?.business_name})`}
            footer
            button1Text="Save"
            loading={buttonLoading}
            button1Click={() => button1Click()}
        >
            <div className="payments_ui">
                <Row className="mb-3">
                    <Col md={4}>
                        <Input
                            maxLength={10}
                            isRequired
                            label="Amount"
                            placeholder="Enter Amount"
                            inputType="currency"
                            value={paymentData?.amount}
                            currencyPrefix
                            onValueChange={val => {
                                if (val <= 999999 || isNullOrEmpty(val)) {
                                    setPayentData({ ...paymentData, amount: val });
                                } else {
                                    warn(MAX_AMT_LIMIT_REACHED('₹9,99,999'));
                                }
                            }}

                        />
                    </Col>
                    <Col md={4}>
                        <Input
                            maxLength={50}

                            label="Notes"
                            placeholder="Enter Notes"
                            type="text"
                            value={paymentData?.remark}
                            onChange={(e) => setPayentData({ ...paymentData, remark: e.target.value })}
                        />

                    </Col>
                    <Col md={4}>
                        <div className='d-flex gap-2 justify-content-center align-items-center h-100 m-auto'>
                            <CustomTooltip text="Create Entry">
                                <IconResource
                                    type="doubletick"
                                    size={18}
                                    className="hand-hover"
                                    color="var(--green)"
                                    onClick={() => {
                                        if (validatePayment()) {
                                            if (paymentData?.amount > 0) {

                                                setAddedPayment((oldData) => {
                                                    return [...oldData, paymentData]
                                                })
                                            }
                                            setPaymentList((oldData) => {

                                                // let data = {
                                                //     ...paymentData,
                                                //     created_at: getFormattedDate()
                                                // }

                                                let updatedData = [...oldData.data, paymentData];

                                                // Return the updated oldData object with the new data added
                                                return {
                                                    ...oldData,
                                                    data: updatedData
                                                };
                                            });
                                            clearRow()
                                        }
                                    }}
                                />
                            </CustomTooltip>
                            <CustomTooltip text="Delete Entry">
                                <IconResource
                                    type="delete"
                                    size={18}
                                    className="hand-hover"
                                    onClick={() => clearRow()}
                                />
                            </CustomTooltip>
                        </div>

                    </Col>
                </Row>
                <div className="top_bar">
                    <Text type="h2" text="" />
                    <Row className="search_filter">
                        <Col lg={4} xs={12} sm={4}>
                            <div>
                                <Input
                                    placeholder="Search"
                                    id="searchInput"
                                    suffixIcon={"search"}
                                    value={paymentSearchQuery}
                                    onChange={(e) => setPaymentSearchQuery(e.target.value)}
                                    onClickIconSuff={() => {
                                        if (type === "details") {
                                            getPaymentList(dispatcherDetail.id)
                                        }
                                        else {
                                            getPaymentList(dispatcherDetail)
                                        }
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {

                                            if (type === "details") {
                                                getPaymentList(dispatcherDetail.id)
                                            }
                                            else {
                                                getPaymentList(dispatcherDetail)
                                            }

                                        }
                                    }}
                                />
                            </div>
                        </Col>

                        <Col lg={8} sm={8}>
                            <div className="date_range_picker">
                                <DateRangePicker
                                    startDate={paymentDates?.startDate}
                                    setStartDate={(startDate) => setPaymentDates({ ...paymentDates, startDate })}
                                    endDate={paymentDates?.endDate}
                                    setEndDate={(endDate) => setPaymentDates({ ...paymentDates, endDate })}
                                    onChange={(startDate, endDate) => setPaymentDates({ startDate, endDate })}
                                    datePickerStartRef={paymentdatePickerStartRef}
                                    datePickerEndRef={paymentdatePickerEndRef}
                                    showDateShortcuts={true}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                    paymentLoading ?
                        <MainLoader /> : paymentList?.data?.length > 0 ?
                            <>


                                <Table responsive hover className="mt-4">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Notes</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            paymentList?.data?.map((i) => (<tr>
                                                <td>{i?.created_at ? showDate(i?.created_at) : null}</td>
                                                <td>{formattedValue(i?.amount ?? 0, true, true)}</td>
                                                <td>{i?.remark || "-"}</td>
                                                <td onClick={() => deletePayment(i.id)}>
                                                    {
                                                        i?.id && <IconResource type="delete" />
                                                    }
                                                </td>
                                            </tr>))
                                        }

                                    </tbody>
                                </Table>

                                < CustomPagination
                                    activePage={activePage}
                                    onPageChange={onPageChangePayment}
                                    pageLength={paymentList?.per_page}
                                    totalData={paymentList?.total}
                                    lastPage={paymentList?.last_page}
                                /></> :
                            <>

                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Notes</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </Table>
                                <div className="no_data d-flex  flex-column justify-content-center align-items-center position-relative">
                                    <img
                                        src={noData}
                                        className="img-fluid"
                                        style={{ width: "300px" }}
                                    />
                                    <p className="text-center">You haven’t made any payments yet.</p>
                                </div></>
                }



            </div>
        </BasicModal>
    );
};

export default PaymentModal;
