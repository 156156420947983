export const CONTACT_ADMIN = "Please contact ADMIN";
export const LOGIN_AGAIN = "You've been logged out. Please log in again";
export const BROWSER_STORAGE_ERROR = "Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.";

export const EMAIL_REQUIRED = "Please enter the email";
export const PASSWORD_REQUIRED = "Please enter the password";
export const EMAIL_PASSWORD_REQUIRED = "Please enter email and password";
export const NAME_REQUIRED = (name) => `Please enter ${name || ""} Name`;
export const PHONE_REQUIRED = "Please enter Phone Number";
export const FEE_REQUIRED = "Please enter Fee";
export const INCORRECT_PHONE = "Please enter a valid Phone Number";
export const FIRST_NAME_REQUIRED = "Please enter First Name";
export const PHOTO_REQUIRED = "Please upload your photo";
export const FIRST_NAME_LIMIT_ERROR = 'First Name should be less than 30 characters';
export const LAST_NAME_REQUIRED = "Please enter Last Name";
export const LAST_NAME_LIMIT_ERROR = 'Last Name should be less than 30 characters';
export const CONFIRM_PASSWORD_REQUIRED = "Please confirm your password";
export const OLD_PASSWORD_REQUIRED = "Please enter Old Password";
export const NEW_PASSWORD_REQUIRED = "Please enter New Password";
export const NEW_CONFIRM_PASSWORD_REQUIRED = "Please confirm your Password";
export const INVALID_PASSWORD = "Please enter password between 6 to 12 characters";
export const SAME_OLD_NEW_PASS = "Old Password and New Password cannot be the same";
export const PASSWORD_CONFIRM_PASSWORD_MATCH = "Password and confirm password does not match";
export const PASSWORD_FIELD_INFO = "Leave Password field untouched if you don't want to change Password";
export const OTP_REQUIRED = "Please enter OTP to proceed";
export const VALID_OTP = "Please enter 4 Digit OTP to proceed";
export const OTP_SENT_SUCCESS = "Email Validation OTP has been sent successfully";
export const OTP_SENT_FAILURE = "Please retry after 30 seconds.";
export const INCORRECT_EMAIL = "The email must be a valid email address";
export const SOMETHING_WENT_WRONG = "We seem to have hit a snag. Please retry in a while.";
export const DATEPICKER_PLACEHOLDER = "DD/MM/YYYY";
export const COUNTRY_REQUIRED = "Please enter Country";
export const BUSINESS_TYPE_REQUIRED = 'Please select Business Type';
export const BUSINESS_DATE_REQUIRED = 'Please select Business Date';
export const BUSINESS_WEBSITE_REQUIRED = 'Please enter Business Website';
export const INVALID_WEBSITE = 'Business Website must be a valid URL';
export const CURRENCY_REQUIRED = "Please select Currency";
export const GST_REQUIRED = "Please select GST %";
export const LOGO_REQUIRED = "Please select Logo";
export const ADDRESS_REQUIRED = "Please Add Address";
export const VISIT_DATE_REQUIRED = "Please Add Visit Date";
export const DOB_REQUIRED = "Please Add Date Of Birth";
export const CITY_REQUIRED = (name) => `Please Add ${name ? name : ""} City`;
export const ADD_BUSINESS = "Let's get started by adding a new Business first";
export const MAX_DAYS_ALLOWED = days => `Max ${days || 90} days allowed`;
export const PAGE_NOT_ACCESSABLE = "You cannot access this page";

// ---------- packages validations --------------
export const COVERED_KM_INVALID = "Covered KM must be a non-negative number"
export const DESCRIPTION_REQUIRED = "Description is required"
export const DRIVER_CHARGES_INVALID = "Driver Charges must be a non-negative number"
export const EXTRA_HR_CHARGES_INVALID = "Extra HRs Charges must be a non-negative number"
export const EXTRA_KM_CHARGES_INVALID = "Extra KM Charges must be a non-negative number"
export const FLAT_RATE_INVALID = "Flat Rate must be a non-negative number"
export const MIN_KM_INVALID = "Min KM must be a non-negative number"
export const NIGHT_CHARGES_INVALID = "Night Charges must be a non-negative number"
export const PER_KM_INVALID = "Per KM must be a non-negative number"
export const STATUS_REQUIRED = "Status is required"
export const VEHICLE_TYPE_REQUIRED = "Vehicle Type is required"
// ---------------- vehicle validations --------------
export const VEHICLE_NUMBER_REQUIRED = "Vehicle number required";
export const OWNER_REQUIRED = "Owner required";
export const INSURANCE_EXPIRY_DATE_REQUIRED = "Insurance expiry date required";
export const POLLUTION_EXPIRY_DATE_REQUIRED = "Pollution expiry date required";
export const SERVICE_EXPIRY_DATE_REQUIRED = "Service expiry date required";

// ------------------- users validations--------------------
export const INVALID_EMAIL = "Invalid email format."
export const MOBILE_NUMBER_REQUIRED = "Mobile number is required."
export const INVALID_MOBILE_NUMBER = "Invalid mobile number format."
export const ROLE_NAME_REQUIRED = "Role name is required."
export const REMARK_REQUIRED = "Remark is required."
export const MAX_LENGTH_EXCEEDED = "Maximum length exceeded."
export const MAX_AMT_LIMIT_REACHED = (amount) => `Max ${amount ?? "₹9,99,999.99"} Amount is allowed`;
export const MAX_PERCENTAGE_LIMIT_REACHED = `Max 100 % is allowed`;
export const MAX_FLAT_LIMIT_REACHED = (length) => `Max ${length}/- is allowed`;

export const minimumLengthRequired = (length, value) => {
    return `Minimum ${length} ${value ?? "Characters"} required`
}
export const maximumLengthAllowed = (length, value) => {
    return `Maximum ${length} ${value ?? "Characters"} Allowed`
}


export const DUE_DATE_REQUIRED = "Due Date is required";
export const INVOICE_NUMBER_REQUIRED = "Invoice Number is required";
export const BILLED_BY_REQUIRED = "Billed By is required";
export const BILLED_TO_REQUIRED = "Billed To is required";
export const ITEM_REQUIRED = "Item is required";
export const QUANTITY_REQUIRED = "Quantity is required";
export const RATE_REQUIRED = "Rate is required";
export const AMOUNT_REQUIRED = "Amount is required";
export const ITEMS_REQUIRED = "Please Add some Items to continue";

export const BUSINESS_NAME_REQUIRED = "Please enter Business Name";
export const LEAD_NAME_REQUIRED = "Please enter Lead Name";
export const PHONE_NUMBER_WRONG = "Please enter Correct Phone Number";
export const ACC_HOLDER_NAME_REQUIRED = "Please enter Account Holder Name";
export const BANK_REQUIRED = "Please select a Bank";
export const BANK_NAME_REQUIRED = "Please enter Bank Name";
export const BANK_ADDRESS_REQUIRED = "Please enter Bank Address";
export const BANK_ACC_NO_REQUIRED = "Please enter Bank Account Number";
export const BANK_IFSC_REQUIRED = "Please enter Bank IFSC Code";
export const BANK_IFSC_INCORRECT = "Please enter Correct IFSC Code";
export const BANK_UPI_REQUIRED = "Please enter Bank UPI ID";
export const INVOICE_NOT_FOUND = "Invoice not found";
export const P_INVOICE_NOT_FOUND = "Proforma Invoice not found";

// ------------------ bookings validations----------------------
export const PACKAGE_REQUIRED = "Please select a package";
export const START_CITY_REQUIRED = "Start Point is required."
export const END_CITY_REQUIRED = "End Point is required."
export const START_LAT_LNG_REQUIRED = "Start latitude and longitude are required."
export const END_LAT_LNG_REQUIRED = "End latitude and longitude are required."
export const DRIVER_ID_REQUIRED = "Driver ID is required."
export const VEHICLE_ID_REQUIRED = "Vehicle ID is required."
export const TRAVEL_DATE_REQUIRED = "Travel date is required."
export const TRAVEL_TIME_REQUIRED = "Travel time is required."
export const CUST_ID_REQUIRED = "Customer ID is required."
export const CUST_NAME_REQUIRED = "Customer name is required."
export const CUST_NUMBER_REQUIRED = "Customer number is required."
export const INCORRECT_CUST_NUMBER = "Customer phone number is incorrect."
export const FINAL_AMT_REQUIRED = "Fixed amount is required."
export const PAYMENT_AMT_REQUIRED = "Payment amount is required."
export const PENDING_AMT_REQUIRED = "Pending amount is required."
export const PAYMENT_REMARKS_REQUIRED = "payment remarks are required."
export const PAYMENT_MODE_REQUIRED = "Payment mode is required."
export const DISPATCHER_NAME_REQUIRED = "Dispatcher name is required."
export const DISPATCHER_ADDRESS_REQUIRED = "Dispatcher address is required."
export const MINIMUM_MOBILE_DIGIT = "Minimum 10 digit should be required in mobile number"
export const UNDEFINED = "undefined";
export const NOLOADING = "No-Loading"
