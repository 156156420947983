import React from 'react'
import MainLoader from '../_widgets/Loader/MainLoader';
import { Table, Row, Col } from 'react-bootstrap';
import noData from "../../Assets/img/noData.png";
// import { changeDateFormatForDisplay, timeFormat } from "../../Utils/dateUtils";
import { formattedValue, formattedValueDispatcher, getStatusNameById, removeCommaFromAmount } from "../../Services/common";
import { getColorOfStatus } from "../../Utils/appUtils";
import CustomTooltip from '../_widgets/Tooltip/CustomTooltip';
import CustomPagination from '../_widgets/Pagination/Pagination';
import Text from '../_widgets/Text/Text';
import NavBar from '../_widgets/NavBar/NavBar';
import PaymentModal from './PaymentModal';
import { changeDateFormatForDisplay } from '../../Utils/dateUtils';
import AnimatedPage from '../AnimatedPage/AnimatedPage';
import Input from '../_widgets/Input/Input';
import DateRangePicker from '../_widgets/DateRangePicker/DateRangePicker';
import AddEditModal from './AddEditModal';
function Detail({ dispatcherBookingList, onPageChange, activePage, loading, paymentModal, handlePayment, handlePaymentClose, deleteAmount, buttonLoading, upsertPayment, paymentData, setPayentData, paymentList, onPageChangePayment, activePaymentPage, paymentLoading, getDispatcherBooking, searchQuery, setSearchQuery, dates, setDates, datePickerEndRef, datePickerStartRef, paymentSearchQuery, setPaymentSearchQuery, getPaymentList, paymentDates, setPaymentList, setPaymentDates, paymentdatePickerStartRef, paymentdatePickerEndRef, handleAddRow, clearRow, type, validatePayment, deletePayment, setAddedPayment, handleEdit, editModal, handleDispatcherClose, dispatcherData, upsertBooking, setDispatcherData, setVehicleSearchQuery, setCustomerSearchQuery }) {

  return (
    <AnimatedPage>




      {/* <NavBar
        heading={`Dispatch Details `}
        onClick={() => handleBack()}
        Icon="backangle"
      /> */}
      <NavBar
        button="Add Payment"
        button2="Edit Dispatcher"
        buttonIcon={"payment"}
        buttonIcon2={"edit"}
        heading={dispatcherData?.name ? `${dispatcherData?.name} (${dispatcherData?.business_name})` : 'Dispatcher Details'}
        // heading={`${dispatcherBookingList?.dispatcher?.name} (${dispatcherBookingList?.dispatcher?.business_name})` || ''}
        onClick={() => {
          document.body.classList.toggle("side_active")
        }}
        setModalShow={handlePayment}
        setModalShow2={handleEdit}
        Icon="menu"
      />
      <AddEditModal
        modalShow={editModal}
        handleClose={handleDispatcherClose}
        dispatcherData={dispatcherData}
        upsertBookings={upsertBooking}
        buttonLoading={buttonLoading}
        setDispatcherData={setDispatcherData}

        setCustomerSearchQuery={setCustomerSearchQuery}
        setVehicleSearchQuery={setVehicleSearchQuery}
      />
      <PaymentModal
        modalShow={paymentModal}
        handleClose={handlePaymentClose}
        heading='Payment'
        buttonLoading={buttonLoading}
        button1Click={upsertPayment}
        button1Text={'Make Payment'}
        paymentList={paymentList}
        paymentData={paymentData}
        setPayentData={setPayentData}
        deleteAmount={deleteAmount}
        dispatcherDetail={dispatcherBookingList?.dispatcher}
        remarks
        onPageChangePayment={onPageChangePayment}
        activePage={activePaymentPage}
        paymentLoading={paymentLoading}
        handleAddRow={handleAddRow}
        clearRow={clearRow}
        paymentSearchQuery={paymentSearchQuery}
        setPaymentSearchQuery={setPaymentSearchQuery}
        getPaymentList={getPaymentList}
        paymentDates={paymentDates}
        setPaymentDates={setPaymentDates}
        paymentdatePickerStartRef={paymentdatePickerStartRef}
        paymentdatePickerEndRef={paymentdatePickerEndRef}
        type={type}
        validatePayment={validatePayment}
        deletePayment={deletePayment}
        setAddedPayment={setAddedPayment}
        setPaymentList={setPaymentList}
        size="md"
      />
      <div className="patient_list white_card">
        <div className="top_bar">
          <Text type="h6" style={{ marginRight: "auto" }} text={dispatcherBookingList?.bookings?.data.length ? `Total : ${formattedValueDispatcher(dispatcherBookingList.remainingBalance, true, true)}` : '<div> Total : &nbsp <b>-</b></div>'} />

          <Row className="search_filter">
            <Col lg={4} xs={12} sm={4}>
              <div>
                <Input
                  placeholder="Search"
                  id="searchInput"
                  suffixIcon={"search"}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onClickIconSuff={() => getDispatcherBooking(true)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      getDispatcherBooking(true)
                    }
                  }}
                />
              </div>
            </Col>

            <Col lg={8} sm={8}>
              <div className="date_range_picker">
                <DateRangePicker
                  startDate={dates?.startDate}
                  setStartDate={(startDate) => setDates({ ...dates, startDate })}
                  endDate={dates?.endDate}
                  setEndDate={(endDate) => setDates({ ...dates, endDate })}
                  onChange={(startDate, endDate) => setDates({ startDate, endDate })}
                  datePickerStartRef={datePickerStartRef}
                  datePickerEndRef={datePickerEndRef}
                  showDateShortcuts={true}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="custom_table position-relative">
          {loading
            ? <MainLoader />
            : dispatcherBookingList?.bookings?.data?.length > 0 ? (<>
              <div>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th className="text-start">Booking Info</th>
                      <th className="text-start">Customer Name</th>

                      <th className="text-center">Amount Collected</th>
                      <th className="text-center">Bill Amount</th>

                      <th className="text-center">Status</th>

                    </tr>
                  </thead>
                  <tbody>
                    {dispatcherBookingList?.bookings?.data?.map(data => {
                      return (
                        <tr key={data?.id}>
                          <td className="text-start"><CustomTooltip text={data?.packages?.name}>

                            <div>
                              <div className="d-flex align-items-start gap-3">

                                <p style={{ fontSize: "13px", color: "var(--black2)" }}>{`${changeDateFormatForDisplay(data?.travel_date)}`}</p>
                              </div>
                              {/* <p>
                          {slice(data?.packages?.name, 20)}
                        </p> */}
                              <small>BID:{data?.id}</small>

                            </div></CustomTooltip></td>
                          <td className="text-start">{data.cust_name}</td>

                          <td className="text-center">{formattedValue(removeCommaFromAmount(data?.coll_to_cust || 0), true, true)}</td>
                          <td className="text-center">{formattedValue(removeCommaFromAmount(data?.bill_to_dptr || 0), true, true)}</td>

                          <td className="text-center"><span className={`status status__${getColorOfStatus(data.status)}`}>{getStatusNameById(data.status)}</span></td>

                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <CustomPagination
                activePage={activePage}
                onPageChange={onPageChange}
                pageLength={dispatcherBookingList?.bookings?.per_page}
                totalData={dispatcherBookingList?.bookings?.total}
                lastPage={dispatcherBookingList?.bookings?.data?.last_page}
              />
            </>) : (<>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th className="text-start">Booking Info</th>
                    <th className="text-start">Customer Name</th>
                    <th>Company Name</th>
                    <th>Bill Amount</th>
                    <th>Amount Collected</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </Table>

              <div className="no_data position-relative">
                <img
                  src={noData}
                  className="img-fluid"
                  style={{ width: "300px" }}
                />
                <p className="text-center">There is no data to display.</p>
              </div>
            </>)}
        </div>
      </div>


    </AnimatedPage>
  )
}

export default Detail