import { useRef, useState, useEffect } from 'react'
import * as apiHelper from "../../Services/ApiHelper";
import * as toast from "../../Wrapper/toast/toast";
import { changeDateFormatForServer } from "../../Utils/dateUtils";
import { isNullOrEmpty } from '../../Utils/stringUtils';
import * as stringConstants from "../../Constants/stringConstants";
import { isEmailValid, setUsersListInLocal, setVehicleListInLocal } from '../../Services/common';
import DispatcherUI from './DispatcherUI';
import { isValidPhoneNumber } from 'react-phone-number-input';

const Dispatcher = () => {

    const initialDispatcherData = {
        id: null,
        address: "",
        alt_mno: "",
        business_name: "",
        email: "",
        id: null,
        image: null,
        mno: "",
        name: "",
        owner: null,
        remark: "",
        status: 0,
        action: "Add",
        password: "",
        imagePreview: ''

    }

    const initialPaymentData = {
        amount: "",
        remark: ""
    }
    const [modalShow, setModalShow] = useState(false);
    const [dispatcherList, setDispatcherList] = useState([]);
    const [dispatcherData, setDispatcherData] = useState(initialDispatcherData);
    const [paymentList, setPaymentList] = useState([]);
    const [paymentData, setPayentData] = useState(initialPaymentData)
    const [dispatcherDetail, setDispatcherDetails] = useState(null)
    const [paymentModal, setPaymentModal] = useState(false)
    const [dates, setDates] = useState({ startDate: null, endDate: null });
    const [searchQuery, setSearchQuery] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [activePaymentPage, setActivePaymentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const [selectedStatus, setSelectedStatus] = useState([]);


    const [customerSearchQuery, setCustomerSearchQuery] = useState('');
    const [vehicleSearchQuery, setVehicleSearchQuery] = useState('');
    const [paymentLoading, setPaymentLoading] = useState(false)
    const [paymentRows, setPaymentRows] = useState([])
    const datePickerStartRef = useRef();
    const datePickerEndRef = useRef();
    const paymentdatePickerStartRef = useRef();
    const paymentdatePickerEndRef = useRef();
    const [paymentSearchQuery, setPaymentSearchQuery] = useState("")
    const [paymentButtonLoading, setPaymentButtonLoading] = useState(false)
    const [paymentDates, setPaymentDates] = useState({ startDate: null, endDate: null })
    const [addedPayment, setAddedPayment] = useState([])
    const handleStatusChange = (options) => setSelectedStatus(options);


    let initialPlacesOptions = [];
    useEffect(() => {
        if (((dates.startDate !== null && dates.endDate !== null) || (dates.startDate === null && dates.endDate === null))) {
            fetchDispatcherList();
        }
    }, [activePage, dates, selectedStatus]);
    useEffect(() => {
        if (((paymentDates.startDate !== null && paymentDates.endDate !== null) || (paymentDates.startDate === null && paymentDates.endDate === null))) {
            if (dispatcherDetail && paymentModal === true) {
                getPaymentList(dispatcherDetail)
            }
        }
    }, [activePaymentPage, paymentDates]);
    useEffect(() => {
        if (dispatcherDetail && paymentModal === true) {
            getPaymentList(dispatcherDetail)
        }
    }, [activePaymentPage]);


    const onPageChange = (page) => {

        if (page !== activePage) {
            setActivePage(page);
        }
    };
    const onPageChangePayment = (page) => {

        if (page !== activePaymentPage) {
            setActivePaymentPage(page);
        }
    };

    const fetchDispatcherList = (search) => {
        const query = `?page=${search ? 1 : activePage}&limit=10`;
        const data = {
            startDate: changeDateFormatForServer(dates.startDate),
            endDate: changeDateFormatForServer(dates.endDate),
            query: searchQuery,
            status: selectedStatus,

        }

        search && setActivePage(1)
        setLoading(true);
        apiHelper.getDispatcherList(query, data).then((response) => {
            if (response?.isSuccess) {
                setDispatcherList(response.data);
            } else {
                toast.warn(response?.message);
            }
            setLoading(false);
        });
    }

    const getPaymentList = (data, loading) => {
        if (loading !== stringConstants.NOLOADING) {
            setPaymentLoading(true)
        }

        const query = `?page=${paymentSearchQuery ? 1 : activePaymentPage}&limit=10&startDate=${changeDateFormatForServer(paymentDates.startDate)}&endDate=${changeDateFormatForServer(paymentDates.endDate)}&query=${paymentSearchQuery}`;

        paymentSearchQuery && setActivePaymentPage(1)


        apiHelper.getPaymentList(query, data.id).then((response) => {
            if (response?.isSuccess) {
                setPaymentList(response.data);
                if (loading !== stringConstants.NOLOADING) {
                    setPaymentLoading(false)
                }
            } else {
                toast.warn(response?.message);
                if (loading !== stringConstants.NOLOADING) {
                    setPaymentLoading(false)
                }
            }

        });

    }
    const clearRow = () => {
        setPayentData(initialPaymentData)
    }
    const validatePayment = () => {

        let validData = true;
        const {
            remark, amount } = paymentData;
        if (isNullOrEmpty(amount)) {
            toast.warn(stringConstants.AMOUNT_REQUIRED);
            validData = false;
        }

        return validData
    }
    const validateData = () => {

        const {
            address, business_name, email, mno, name, owner, alt_mno
        } = dispatcherData;
        let validData = true;

        if (isNullOrEmpty(business_name)) {
            toast.warn(stringConstants.BUSINESS_NAME_REQUIRED);
            validData = false;
        }
        else if (isNullOrEmpty(name)) {
            toast.warn(stringConstants.DISPATCHER_NAME_REQUIRED);
            validData = false;
        }
        else if (isNullOrEmpty(email)) {
            toast.warn(stringConstants.EMAIL_REQUIRED);
            validData = false;
        }
        else if (!isEmailValid(email)) {
            toast.warn(stringConstants.INCORRECT_EMAIL);
            validData = false;
        }
        else if (isNullOrEmpty(mno)) {
            toast.warn(stringConstants.INCORRECT_CUST_NUMBER);
            validData = false;
        }
        else if (!isValidPhoneNumber(mno)) {
            toast.warn(stringConstants.INCORRECT_PHONE);
            validData = false;
        }
        // else if (alt_mno === stringConstants.UNDEFINED && !isValidPhoneNumber(alt_mno)) {
        //     toast.warn(stringConstants.INVALID_MOBILE_NUMBER);
        //     validData = false
        // }
        else if (isNullOrEmpty(address)) {
            toast.warn(stringConstants.DISPATCHER_ADDRESS_REQUIRED);
            validData = false;
        }



        return validData;
    };
    const getFilenameFromUrl = (url) => {
        return url.split('/').pop();  // Extracts the filename from the URL
    };

    const convertImageUrlToFile = async (imageUrl) => {
        try {
            // Fetch the image from the URL
            const response = await fetch(imageUrl);

            // Check if the response is OK (status 200)
            if (!response.ok) {
                throw new Error('Failed to fetch image. Status: ' + response.status);
            }

            // Check if the response is of type image/jpeg, image/png, or other image type
            const contentType = response.headers.get('Content-Type');
            if (!contentType || !contentType.startsWith('image/')) {
                throw new Error('The URL does not point to an image. Content-Type: ' + contentType);
            }

            // Convert the response to a Blob (binary large object)
            const blob = await response.blob();

            // Extract the filename from the URL
            const fileName = getFilenameFromUrl(imageUrl);

            // Create a new File object with the blob, filename, and MIME type
            const file = new File([blob], fileName, {
                type: contentType,  // Ensure the MIME type is correctly passed
                lastModified: Date.now(),
            });


            return file;
        } catch (error) {
            console.error("Error converting image URL to file:", error);
            throw error;
        }
    };


    const upsertBooking = async () => {
        if (validateData()) {
            setButtonLoading(true);
            const { address, alt_mno, business_name, email, image, mno, name, owner, remark, id, password } = dispatcherData;

            const data = new FormData();
            data.append('address', address || '');
            data.append('alt_mno', alt_mno || '');
            data.append('business_name', business_name);
            data.append('email', email);
            data.append('mno', mno);
            data.append('name', name);
            data.append('owner', owner);
            data.append('remark', remark);
            data.append('password', password);

            if (id !== null) {
                data.append('id', id);  // Only append the ID if it is not null
            }

            // Handle the image upload
            if (image) {
                if (typeof image === 'string') {  // If the image is a URL (string)
                    try {
                        const imageFile = await convertImageUrlToFile(image);

                        // Append the image as a file
                        data.append('image', imageFile);
                    } catch (error) {
                        console.error("Failed to convert image URL to File", error);
                    }
                } else if (image instanceof File) {  // If the image is a file object
                    data.append('image', image);  // Append the file directly
                }
            }

            // Make the API request with FormData
            try {
                const response = await apiHelper.upsertDispatcher(data);
                setButtonLoading(false);
                if (response?.isSuccess) {
                    toast.success(response?.message);
                    handleClose();

                    fetchDispatcherList();
                } else {
                    toast.warn(response?.message);
                }
            } catch (error) {
                console.error("Error during API request", error);
                setButtonLoading(false);
            }
        }
    };

    const upsertPayment = () => {


        if (addedPayment?.length > 0) {
            setPaymentButtonLoading(true)

            let data = {
                "payments": addedPayment
            }

            apiHelper.upsertPayment(dispatcherDetail?.id, data).then(response => {

                if (response?.isSuccess) {
                    toast.success(response?.message);
                    setPaymentButtonLoading(false)
                    setPayentData(initialPaymentData)
                    getPaymentList(dispatcherDetail, stringConstants.NOLOADING)
                    setPaymentModal(false)
                    setAddedPayment([])
                    handlePaymentClose()
                } else {
                    setPaymentButtonLoading(false)
                    setPayentData(initialPaymentData)
                    getPaymentList(dispatcherDetail, stringConstants.NOLOADING)
                    setPaymentModal(false)
                    setAddedPayment([])
                    toast.warn(response?.message);
                    setAddedPayment([])
                    handlePaymentClose()

                }

            }).finally(() => setButtonLoading(false))


        }
        else {
            setPaymentButtonLoading(false)
            setPayentData(initialPaymentData)
            getPaymentList(dispatcherDetail, stringConstants.NOLOADING)
            setPaymentModal(false)
            setAddedPayment([])
            handlePaymentClose()

        }

    };
    const deletePayment = (id) => {
        apiHelper.deletePayment(id).then((res) => {
            if (res.isSuccess) {
                getPaymentList(dispatcherDetail, stringConstants.NOLOADING)
                toast.success(res.message)
            }
            else {
                toast.warn(res.message)
            }
        })
    }

    const handleClose = () => {

        setModalShow(false);
        setDispatcherData(null);
        setPayentData(initialPaymentData);
        setPaymentList([]);  // Clearing payment list

        setDispatcherData({
            ...initialDispatcherData,
            userOptions: dispatcherData.userOptions,
            vehicleOptions: dispatcherData.vehicleOptions,
            startPointOptions: initialPlacesOptions,
            endPointOptions: initialPlacesOptions
        });
    };








    useEffect(() => {
        let timer = setTimeout(getSearchedCustomerOptions, 200);
        return () => clearTimeout(timer);
    }, [customerSearchQuery]);

    const getSearchedCustomerOptions = async () => {
        setButtonLoading('customer');
        const userResp = await apiHelper.getUserList('?query=' + customerSearchQuery, { roles: [4] });
        if (userResp?.isSuccess) {
            const userOptions = setUsersListInLocal(userResp?.data?.data);
            setDispatcherData({ ...dispatcherData, userOptions });
        } else {
            toast.error(userResp?.message);
        }
        setButtonLoading(false);
    }

    useEffect(() => {
        let timer = setTimeout(getSearchedVehicleOptions, 200);
        return () => clearTimeout(timer);
    }, [vehicleSearchQuery]);

    const getSearchedVehicleOptions = async () => {
        setButtonLoading('vehicle');
        const vehicleResp = await apiHelper.getVehicleList('?query=' + vehicleSearchQuery, {});
        if (vehicleResp?.isSuccess) {
            const vehicleOptions = setVehicleListInLocal(vehicleResp?.data?.data);
            setDispatcherData({ ...dispatcherData, vehicleOptions });
        } else {
            toast.error(vehicleResp?.message);
        }
        setButtonLoading(false);
    }
    const clearFilter = (e, state) => {
        const dropdownMenu = e.target.closest('#dropdown-basic');
        dropdownMenu.click();
        // e?.stopPropagation();
        state([])
    }
    const handlePayment = () => {
        setPaymentModal(true)
    }
    const handlePaymentClose = () => {

        setPayentData(initialPaymentData);
        setPaymentList([]);
        setPaymentModal(false);
        setPaymentDates({ startDate: null, endDate: null })
        setPaymentSearchQuery("");
        setActivePaymentPage(1)
    }
    const deleteAmount = () => {
        toast.warn("Option will be available soon")
    }
    const handleAddRow = (data) => {

        setPayentData(initialPaymentData)
        setPaymentRows((oldData) => {
            return [...oldData, data]
        })
    }
    return (
        <DispatcherUI
            dispatcherList={dispatcherList}
            loading={loading}
            buttonLoading={buttonLoading}
            modalShow={modalShow}
            setModalShow={setModalShow}
            handleClose={handleClose}
            activePage={activePage}
            onPageChange={onPageChange}
            onPageChangePayment={onPageChangePayment}

            activePaymentPage={activePaymentPage}
            dates={dates}
            setDates={setDates}
            datePickerStartRef={datePickerStartRef}
            datePickerEndRef={datePickerEndRef}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}

            dispatcherData={dispatcherData}
            setDispatcherData={setDispatcherData}
            upsertBooking={upsertBooking}
            fetchDispatcherList={fetchDispatcherList}

            selectedStatus={selectedStatus}
            handleStatusChange={handleStatusChange}
            setCustomerSearchQuery={setCustomerSearchQuery}
            setVehicleSearchQuery={setVehicleSearchQuery}
            clearFilter={clearFilter}
            setSelectedStatus={setSelectedStatus}

            paymentList={paymentList}
            paymentData={paymentData}
            getPaymentList={getPaymentList}
            setPayentData={setPayentData}
            upsertPayment={upsertPayment}
            paymentModal={paymentModal}
            setPaymentModal={setPaymentModal}
            handlePayment={handlePayment}
            handlePaymentClose={handlePaymentClose}
            deleteAmount={deleteAmount}
            dispatcherDetail={dispatcherDetail}
            paymentLoading={paymentLoading}
            handleAddRow={handleAddRow}
            paymentRows={paymentRows}
            clearRow={clearRow}
            paymentSearchQuery={paymentSearchQuery}
            setPaymentSearchQuery={setPaymentSearchQuery}
            paymentDates={paymentDates}
            setPaymentDates={setPaymentDates}
            paymentdatePickerStartRef={paymentdatePickerStartRef}
            paymentdatePickerEndRef={paymentdatePickerEndRef}
            setDispatcherDetails={setDispatcherDetails}
            setPaymentList={setPaymentList}
            validatePayment={validatePayment}
            deletePayment={deletePayment}
            setAddedPayment={setAddedPayment}
            paymentButtonLoading={paymentButtonLoading}
        />
    );
}

export default Dispatcher;
